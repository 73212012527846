@font-face {
  font-family: RobotoLight;
  src: url('./fonts/Roboto-Light.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: RobotoBlond;
  src: url('./fonts/Roboto-Black.ttf');
}
@font-face {
  font-family: MonseratBold;
  src: url('./fonts/Montserrat-Bold.ttf');
}
@font-face {
  font-family: MonseratBlack;
  src: url('./fonts/Montserrat-Black.ttf');
}

a {
  text-decoration: none !important;
  color: inherit !important;
}
.service-container {
  padding-bottom: 80px;
}
.contact-icon-face {
  height: 20px;
  padding-right: 20px;
  padding-left: 5px;
}
.contact-icon-linked {
  width: 20px;
  padding-left: 5px;
}
.carousel-item {
  /*transition: none !important;*/
}
.player-style {
  width: 100% !important;
  height: calc( 100vw / 1.77 ) !important;
}
.grecaptcha-badge {
  display: none;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.line {
  width: 150px;
  height: 0;
  border: 3px solid #000;
  margin: 1px;
  display:inline-block;
}
.line-ment {
  width: 100px;
  height: 0;
  border: 3px solid #71c2b3;
  margin: 1px;
  display:inline-block;
}
.home-title-ment{
  color:#71c2b3;
  font-size: 70px;
  text-align: left;
  font-family: 'RobotoBlond',sans-serif !important;
  font-weight: 900;
}
.margin-service{
  margin-left:0px;
}

.font-heder{
  color: #000 !important;
  font-family: 'Roboto', sans-serif !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.header-contact{
  background-color: #71c2b3!important;
  border-radius: 50px;
}
.header{
  background-color: #fff!important;
  font-size: 15px;
}
.footer{
  background-color: #000;
  color:#fff;
  border-top-left-radius: 35px ;
  border-top-right-radius: 35px;
  text-align: left;
  padding: 35px;
}
.button-footer{
  background-color: #71c2b3!important;
  border-color: #000!important;
  font: larger;
  width:120px ;
  color: #000!important;
  font-family: 'RobotoBlond', sans-serif !important;
  font-weight: bold;
}
.line-footer{
  border-bottom: solid white 1px;
}
.form-footer{
  background-color: #000!important;
  color:#fff!important;
  border: #fff;
  font-family: 'Roboto',sans-serif !important;
}
.form-footer::placeholder{
  color:#fff!important;
}
.footer-text-title{
  font-size: 70px;
  font-weight: bold;
  line-height: 75px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
}
.footer-text-desc{
color: #d2d2d2;
text-align: left;
}
.footer-text-subtitle{
  font-size: 35px;
  font-weight: 200;
  color: #fff;
  font-family: 'MonseratBold',sans-serif !important;
}
.section {
  padding-top: 40px;
  padding-bottom: 40px;
}
.video-home{
  width: 100%;
}
.img-carr{
  height: 80%!important;
  padding-top: 20px;
  background-color: transparent;
  border-top-left-radius: 45px;
  margin-top: 15px;
}
.nosotros-title{
  font-family: 'MonseratBlack',sans-serif !important;
  font-size: 40px;
  text-align: left!important;
}
.photo-desc {
  position: relative;
}
.title-item{
  font-weight: bolder;
  color:#000;
  font-size: 20px;
  font-family: 'MonseratBold',sans-serif !important;
}
.subtitle-item{
  font-size: 15px;
  font-family: 'Roboto',sans-serif !important;
  text-align: right;
  padding: 0px;
}
.title-desc{
  font-weight: bolder;
  color:#000;
  font-family: 'MonseratBold',sans-serif !important;
  font-size: 10px;
}
.home-album{
  width: 100%!important;
}
.nosotros-desc{
  font-family: 'RobotoLight',sans-serif !important;
  font-size: 20px;
  text-align: center;
  color:#57585d;
  padding: 50px 50px 0 50px;
}
.home-pos{
  background-color: #000;
  color:#7b9baf;
  font-size: 15px;
  text-align: left;
}
.mas-service{
  height: 27px;
}
.home-title-white{
  color: #fff;
  font-size: 60px;
  line-height: 65px;
  font-weight: 900;
}
.home-subtitle-white{
  color: #fff;
  font-size: 30px;
}
.home-title-black{
  color:#000;
  font-size: 70px;
  text-align: left;
  font-family: 'RobotoBlond',sans-serif !important;
  font-weight: 900;
  line-height: 75px;
}
.home-title-green{
  color:#F6C10F;
  font-size: 60px;
  text-align: left;
  font-family: 'RobotoBlond',sans-serif !important;
  font-weight: 900;
}

.home-services{
  background-color: #000;
  color:#fff;
  text-align: left;
  font-size: 20px;
  font-family: 'Roboto',sans-serif !important;
}
.home-services-title{
  position: relative;
  color:#fff!important;
  top: -8px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  font-family: 'RobotoBlond',sans-serif !important;
  padding-left: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.home-services-mas{
  width: 20px;
}
.home-desc-section {
  font-family: 'RobotoLight',sans-serif;
  font-size: 18px;
  color: #fff;
}
.marquee{
  direction: left;
 
}
.service-tab{
  font-family: 'Roboto',sans-serif !important;
  font-size: 17px;
  padding-left: 0px;
  margin-left: 0px;
}

.containers-photos {
padding: 10px;
background-color: #e6e6e6;
border-radius: 35px;

/*border: 1px solid #000 !important;*/
}
.photos-stants{
  padding: 15px 0px 15px 5px!important;
  float: right;
  margin-left: 0;
}
.photos-stants-right{
  padding: 15px 5px 15px 0px!important;
  margin-right: 0;
}
.containers-photos-left{
  border-top-left-radius: 35px!important;
  border-bottom-left-radius: 35px!important;
}
.containers-photos-right{
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px!important;
  vertical-align: middle!important;
  align-content: center!important;
}

.imgs-containers {
  width: 100%;
  max-width: 100%;
  padding: 0px;
}
.marquee-img{
  padding: 10px 70px;
  height: 100px;
}
.halo-img-xl{
  padding: 25px;
}
.tequila-primos{
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-left: 10px;
}
.img-separation-top{
  padding-top: 25px;
}
.text-desc-book{
  font-size: 15px;
  font-family: 'Roboto',sans-serif !important;
  text-align: left;
  line-height: 20px;
  padding-top: 15px;
  color: #525252;
}
.text-title-book{
  font-family: 'MonseratBold',sans-serif !important;
  font-size: 20px;
  color: #000;
  width: 100%;
  vertical-align: middle;
}

.logo-wite-style {
  width: 150px;
}
.aling-right{
  text-align: right;
  font-family: 'Roboto',sans-serif !important;
  text-align: right;
  font-size: 20px;
  color:#fff!important;
}
.social-media{
  width: 50px;
  padding-right: 20px;
  padding-left: 5px;  
}
.accordion-conten{
  color: #fff!important;
  background-color: #000!important;
}
.accordion-button{
  color: #fff!important;
  background-color: #000!important;
  text-align: left;
  display: contents!important;
}
.accordion{
  border: 1px solid #000000;
}
.accordion-body{
  margin-left: 30px;
}
.accordion-button.collapsed::after {
  background: url('./img/mas.png');
  background-size: 22px;
  height: 22px;
  width: 22px;
  float: left;
  text-align: left;
  order: -1; 
    margin-left: 0; 
    margin-right:0.5em; 
    display: inline;
}
.accordion-button:not(.collapsed)::after  {
  background-image: url("./img/mas.png")!important;
  background-size: 22px;
  height: 22px;
  width: 22px;
  float: left;
  text-align: left;
  order: -1; 
  margin-left: 0; 
  margin-right:0.5em; 
  display: inline;
}
.accordion-button:not(.collapsed)::before  {
  background: url('./img/mas1.png');
  height: 35px;
  width: 35px;
  float: left;
  text-align: left;
  order: -1; 
    margin-left: 0; 
    margin-right:0.5em; 
    border: none;
}
.accordion-item{
  background-color: #000!important;
  color: #fff!important;
  --bs-accordion-border-color: none;
}
.pepsi-background{
  background-color: #e4e4e4;
  border-radius: 35px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: -15px;
}
.margin-zero{
  margin: 0px;
  margin-bottom: 15px;

}

.home-title-book{
  color:#000;
  font-size: 70px;
  text-align: right;
  font-family: 'RobotoBlond',sans-serif !important;
  font-weight: 900;
  border-bottom: solid #000 10px;
  padding: -25px;
}
.accordion-container {
  padding-left: 10% !important;
}

@media (max-width: 460px)
{
  .home-title-white{
    color: #fff;
    font-size: 30px;
    line-height: 30px;
    font-weight: 900;
  }
  .home-subtitle-white{
    color: #fff;
    font-size: 20px;
  }
  .home-desc-section {
    font-family: 'RobotoLight',sans-serif;
    font-size: 15px;
    color: #fff;
    text-align: justify;
  }
  .img-carr{
    height: 80%!important;
    padding-top: 10px;
    background-color: transparent;
    border-top-left-radius: 45px;
    margin-top: 15px;
  }
  .home-subtitle-white{
    color: #fff;
    font-size: 20px;
  }
  .home-desc-section {
    font-family: 'RobotoLight',sans-serif;
    font-size: 10px;
    color: #fff;
  }

  .service-tab{
    font-family: 'Roboto',sans-serif !important;
    font-size: 10px;
    padding-left: 2px;
    margin-left: 10px;
  }
  .home-services-title{
    position: relative;
    top: -8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    font-family: 'RobotoBlond',sans-serif !important;
    padding-left: 10px;
  }
  .mas-service{
    height: 12px;
  }
  .line {
    width: 75px;
    height: 0;
    border: 4px solid #000;
    margin: 1px;
    display:inline-block;
  }
  .img-paloma{
    width: 10px;
    padding: 0px;
  }
  .img-circulo{
    width: 8px;
  }
  .footer-text-title{
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
  }
  .logo-wite-style {
    width: 75px;
  }
  .social-media{
    width: 25px;
    padding-right: 10px;
    padding-left: 2px;  
  }
  .marquee-img{
    padding: 10px 35px;
    height: 70px;
  }
  .home-title-black{
    color:#000;
    font-size: 50px;
    text-align: left;
    font-family: 'RobotoBlond',sans-serif !important;
    font-weight: 900;
  }
  .containers-photos {
    padding: 5px;
    background-color: #e6e6e6;
    border-radius: 20px;
    
    /*border: 1px solid #000 !important;*/
    }
    .text-desc-book{
      font-size: 10px;
      font-family: 'Roboto',sans-serif !important;
      text-align: left;
      line-height: 13px;
      color: #525252;
    }
    .text-title-book{
      font-family: 'MonseratBold',sans-serif !important;
      font-size: 10px;
      color: #000;
      width: 100%;
      vertical-align: middle;
    }

    .home-title-book{
      color:#000;
      font-size: 30px;
      text-align: left;
      font-family: 'RobotoBlond',sans-serif !important;
      font-weight: 800;
      border-bottom: solid #000 10px;
      padding: -10px;
    }
    .img-separation-top{
      padding-top: 10px;
    }
    .photos-stants{
      padding: 15px 5px 15px 0px!important;
      float: right;
      margin-left: 0;
    }
    .colums-padding{
      padding: 0px 5px 0px 5px!important;
    }
    .pepsi-background{
      background-color: #e4e4e4;
      border-radius: 35px;
      padding: 10px;
      margin-top: 5px;
      margin-bottom: -15px;
    }
    .home-title-ment{
      color:#71c2b3;
      font-size: 40px;
      text-align: left;
      font-family: 'RobotoBlond',sans-serif !important;
      font-weight: 900;
    }
    .img-circulo{
      width: 8px;
      margin-right: 5px ;
    }
    .contact-icon-face {
      height: 13px;
      padding-right: 5px;
      padding-left: 0px;
    }
    .contact-icon-linked {
      width: 10px;
      padding-left: 0px;
    }
}